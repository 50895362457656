import React from 'react';

import { Link } from 'react-scroll';

import {
    Box,
    VStack,
    Image,
    Heading,
    Text,
    Button
} from '@chakra-ui/react';

export function Service({ img, header, content }) {
    return (
        <Box boxShadow='2xl' rounded='md' bg='white'>
            <VStack>
                <Image src={img} rounded='lg' fit='cover' height='20em'/>

                <Box padding='1em'>
                    <Heading align='left' size='md'>{ header }</Heading>
                    <Text align='left' padding='1em' >{ content }</Text>

                    <Button colorScheme='blackAlpha'>
                        <Link to='contact' spy={true} smooth={true}>
                            Contact Us
                        </Link>
                    </Button>
                </Box>
            </VStack>
        </Box>
    );
}
