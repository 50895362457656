import React from 'react';

import { 
    Flex,
    Image
} from '@chakra-ui/react';

import bgImg from '../../assets/background-image.jpg';

export function Intro() {
    return (
        <Flex id='intro' bg='white' color='black'>
            <Image fit='contain' src={bgImg} alt='Background Image' />
        </Flex>
    );
}

