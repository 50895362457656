import React from 'react';

import { 
    Flex,
    Stack,
    Heading,
    Text,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    createStandaloneToast
} from '@chakra-ui/react';

import {
    useForm
} from 'react-hook-form';

import axios from 'axios';

export function Contact() {
    const { 
        register, 
        handleSubmit, 
        reset,
        formState: { errors, isSubmitting } 
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange'
    });

    const toast = createStandaloneToast();

    const onSubmit = async data => {
        await axios.post(
            'https://2yb48bk5mi.execute-api.us-west-2.amazonaws.com/production',
            {
                "name": data.name,
                "phone": data.phone,
                "email": data.email,
                "message": data.msg
            }, {
                "Content-Type": "application/json; charset=utf-8"
            }
        ).then(res => {
            reset();

            if (res.status === 200) {
                toast({
                    title: 'Submission received',
                    description: 'Thank you for your submission. We will reach out to you as fast as possible.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true
                });
            } else if (res.status === 404) {
                toast({
                    title: 'An error has occured',
                    description: 'We apologize for the inconvenience. Please try again later or contact us directly through phone or email.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
            }
        }).catch(err => {
                toast({
                    title: 'An error has occured',
                    description: 'We apologize for the inconvenience. Please try again later or contact us directly through phone or email.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                });
        });
    };

    return (
        <Flex id='contact' justifyContent='center' alignItems='center' bg='white' w='100' padding='8em' color='black'>
            <Stack spacing='2em'>
                <Heading>Contact Us</Heading>

                <Text>
                    We're here to help! Send any questions you have over to us. We look forward to hearing from you.
                </Text>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl >
                        <FormLabel htmlFor='name'>Name *</FormLabel>
                        <Input id='name' placeholder='name' type='text' errorBorderColor='red.300' {...register('name', {
                            required: 'This field is required',
                        })}/>
                        { errors.name && <Text fontSize='sm' color='red'>⚠ { errors.name.message }</Text>}

                        <FormLabel htmlFor='phone'>Phone *</FormLabel>
                        <Input id='phone' placeholder='xxx-xxx-xxxx' type='text' {...register('phone', {
                            required: 'This field is required',
                        })}/>
                        { errors.phone && <Text fontSize='sm' color='red'>⚠ { errors.phone.message }</Text>}

                        <FormLabel htmlFor='email'>Email *</FormLabel>
                        <Input id='email' placeholder='example@mail.com' type='email' {...register('email', {
                            required: 'This field is required',
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })}/>
                        { errors.email && <Text fontSize='sm' color='red'>⚠ { errors.email.message }</Text>}

                        <FormLabel htmlFor='msg'>Message *</FormLabel>
                        <Textarea id='msg' placeholder='message' {...register('msg', {
                            required: 'This field is required'
                        })}/>
                        { errors.msg && <Text fontSize='sm' color='red'>⚠ { errors.msg.message }</Text>}
                    </FormControl>
                    <Button marginTop='2em' colorScheme='blackAlpha'type='submit' isLoading={isSubmitting} loadingText='Sending' spinnerPlacement='start' >
                        Submit
                    </Button>
                </form>

            </Stack>
        </Flex>
    );
}

