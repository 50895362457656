import './App.css';

import { Header } from '../components/header/Header.js';

import { Intro } from './intro/Intro.js';
import { About } from './about/About.js';
import { Services } from './services/Services.js';
import { Contact } from './contact/Contact.js';
import { Footer } from './footer/Footer.js';

function App() {
    return (
        <div className="App">
            <Header/>
            <Intro/>
            <About/>
            <Services/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
