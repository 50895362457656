import React from 'react';

import { 
    Flex,
    Stack,
    Heading,
    Text
} from '@chakra-ui/react';

export function About() {
    return (
        <Flex id='about' justifyContent='center' bg='white' w='100' padding='8em' color='black'>
            <Stack>
                <Heading>About</Heading>

                <Text>
                We're the best in our field, and it's all thanks to the incredible relationships we've formed with our clients. Unlike our competitors, we're invested in developing a personal connection with each and every one of our customers, by providing quality service and being available to you 24/7. Get in touch with us when you're ready to learn more; we can't wait to meet you!
                </Text>
            </Stack>
        </Flex>
    );
}

