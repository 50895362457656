import React from 'react';

import { BsTelephone } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { IoLocationOutline } from 'react-icons/io5';

import logo from '../../assets/hearstrnh_logo.jpg';

import { 
    Flex,
    Box,
    Image,
    Stack,
    HStack,
    Text
} from '@chakra-ui/react';

export function Footer() {
    return (
        <Flex bg='lightgrey' alignItems='center' justifyContent='center' color='black' padding='7em'>
            <Stack paddingRight='7em'>
                <Box >
                    <Image boxSize='10em' fit='contain' src={logo} alt='Hearstrnh logo' />
                </Box>
            </Stack>

            <Stack spacing='1.5em' paddingLeft='7em'>
                <HStack>
                    <BsTelephone size='1.5em'/>
                    <Text align='left' fontSize='sm'>+1 (925) 858-1006</Text>
                </HStack>

                <HStack>
                    <AiOutlineMail size='1.5em'/>
                    <Text align='left' fontSize='sm'>kim96@hearstrnh.com</Text>
                </HStack>

                <HStack>
                    <IoLocationOutline size='1.5em'/>
                    <Stack spacing='0.01em'>
                        <Text align='left' fontSize='sm'>5303 Vaughn Rd.</Text>
                        <Text align='left' fontSize='sm'>Montgomery, AL 36116</Text>
                    </Stack>
                </HStack>
            </Stack>

        </Flex>
    );
}
