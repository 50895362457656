import React from 'react';
import logo from '../../assets/hearstrnh_logo.jpg';

import { Link } from 'react-scroll';

import { 
    Flex, 
    Spacer,
    Box, 
    Image,
    Text,
    Button,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import { 
    FaBars
} from 'react-icons/fa';

export function Header() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()

    return (
        <Flex as='header' position='fixed' alignItems='center' backgroundColor='white' w='100%'>
            <Box paddingLeft='2em' _hover={{ cursor: 'pointer' }}>
                <Link to='intro' spy={true} smooth={true}>
                    <Image boxSize='4em' fit='contain' src={logo} alt='Hearstrnh logo'/>
                </Link>
            </Box>

            <Spacer />

            <Box padding='1em'>
                <Button ref={btnRef} bgColor='transparent' onClick={onOpen} >
                    <FaBars size='1.5em'/>
                </Button>

                <Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton size='lg'/>

                        <DrawerBody paddingTop='4em'>
                            <VStack spacing='2em'>
                                <Text _hover={{ cursor: 'pointer' }} onClick={onClose} >
                                    <Link to='about' spy={true} smooth={true} >About</Link>
                                </Text>
                                <Text _hover={{ cursor: 'pointer' }}>
                                    <Link to='services' spy={true} smooth={true} >Services</Link>
                                </Text>
                                <Text _hover={{ cursor: 'pointer' }}>
                                    <Link to='contact' spy={true} smooth={true} >Contact Us</Link>
                                </Text>
                            </VStack>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer> 
            </Box>
        </Flex>
    );
}
