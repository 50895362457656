import React from 'react';
import { Service } from '../../components/service/Service.js';

import { 
    Flex,
    VStack,
    HStack,
    Heading
} from '@chakra-ui/react';

import img1 from '../../assets/DLA_inside1.jpg';
import img2 from '../../assets/DLA_inside5.jpg';
import img3 from '../../assets/DLA_work1.jpg';

export function Services() {
    const filling = {
        'basic': [img1, 'Basic', 'Book today and experience the top-notch quality of our service firsthand.'],
        'standard': [img2, 'Standard', 'We\'ll help you create a tailor-made solution that\'s specific to your needs and wants.'],
        'premium': [img3, 'Premium', 'Our team works around the clock to make sure we deliver on time, every time.'],
    };

    return (
        <Flex id='services' justifyContent='center' bg='white' w='100' p={4} color='black'>
            <VStack>
                <Heading>Our Services</Heading>

                <HStack>
                    <Service img={filling.basic[0]} header={filling.basic[1]} content={filling.basic[2]}/>
                    <Service img={filling.standard[0]} header={filling.standard[1]} content={filling.standard[2]}/>
                    <Service img={filling.premium[0]} header={filling.premium[1]} content={filling.premium[2]}/>
                </HStack>
            </VStack>
        </Flex>
    );
}



